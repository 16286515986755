.mainNav {
    width: var(--mainNav-width-s);
    height: calc(100vh - var(--header-height));
    text-align: center;
    background-color: var(--metallic-gray);
    color: black;
}

.mainNav__icon .icon {
    cursor: pointer;
    margin: auto;
    font-size: var(--mainNav-width-icons-s);
    padding: calc((var(--mainNav-width-s) - var(--mainNav-width-icons-s)) / 2);
}


@media(min-width: 550px) {
    .mainNav {
        width: var(--mainNav-width-m);
    }
    .mainNav__icon .icon {
        font-size: var(--mainNav-width-icons-m);
        padding: calc((var(--mainNav-width-m) - var(--mainNav-width-icons-m)) / 2);
    }
}
@media(min-width: 965px) {
    .mainNav {
        width: var(--mainNav-width-l);
    }
    .mainNav__icon .icon {
        font-size: var(--mainNav-width-icons-l);
        padding: calc((var(--mainNav-width-l) - var(--mainNav-width-icons-l)) / 2);
    }
}
