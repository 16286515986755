.header {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: var(--header-height);
    background-color: var(--bg-light-gray);
}

.header h1 {
    color: var(--details-red);
}