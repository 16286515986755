.channel-main {
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-dark-gray);
}

/* .channel-title {
    color: var(--details-red);
} */
.nav-bar {
    background-color: black;
}

.cafecito-button {
    padding: 3px 0;
}
.channel-url {
    text-decoration: none;
}