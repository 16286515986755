.dashboard {
    position: absolute;
    width: calc(100vw - var(--mainNav-width-s));
    height: calc(100vh - var(--header-height));
    top: var(--header-height);
    left: var(--mainNav-width-s);
    background-color: var(--bg-dark-gray);
    color: white;
    overflow-y: scroll;
}

.dashboard h1 {
    color: var(--details-red);
}

/* SCROLL BAR */
.dashboard::-webkit-scrollbar {
    width: 0.4vw;
}

.dashboard::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo de la pista de la barra de desplazamiento */
}

.dashboard::-webkit-scrollbar-thumb {
    background: whitesmoke; /* Color del pulgar de la barra de desplazamiento */
    border-radius: 8px;
}
.dashboard::-webkit-scrollbar-thumb:hover {
    background: rgba(245, 245, 245, 0.479); /* Color del pulgar de la barra de desplazamiento al pasar el ratón por encima */
}


@media(min-width: 550px) {
    .dashboard {
        width: calc(100vw - var(--mainNav-width-m));
        height: calc(100vh - var(--header-height));
        top: var(--header-height);
        left: var(--mainNav-width-m);
    }
}
@media(min-width: 965px) {
    .dashboard {
        width: calc(100vw - var(--mainNav-width-l));
        height: calc(100vh - var(--header-height));
        top: var(--header-height);
        left: var(--mainNav-width-l);
    }
}
