.channel-main div {
    margin: 5px;
}

.card {
    width: 18rem;
}

.bg-img {
    border-radius: 5px;
}